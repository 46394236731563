import {
  facebookIcon,
  applePodcastIcon,
  instagramIcon,
  twitterIcon,
  youtubeIcon,
  tiktokIcon,
  spotifyIcon,
  applemusicIcon,
  websiteIcon
} from './SocialIcons'
import styles from './_socialMenu.module.scss'

export interface SocialLink {
  name: string
  url: string
}

interface SocialMenuProps {
  links: SocialLink[]
  size?: number
}

const SocialLink: React.FC<any> = ({ name, link }) => {
  return (
    <li className={styles.iconLink}>
      <a target="_blank" rel="noreferrer" href={link}>
        {socialLinksComponents[name]}
      </a>
    </li>
  )
}

const socialLinksComponents = {
  facebook: facebookIcon(),
  instagram: instagramIcon(),
  twitter: twitterIcon(),
  youtube: youtubeIcon(),
  tiktok: tiktokIcon(),
  spotify: spotifyIcon(),
  applemusic: applemusicIcon(),
  website: websiteIcon(),
  applePodcast: applePodcastIcon()
}

const socialLinksOrder = [
  'facebook',
  'instagram',
  'twitter',
  'youtube',
  'tiktok',
  'spotify',
  'applemusic',
  'website',
  'applePodcast'
]

const sortSocialLinks = (links) => {
  return links
    .slice()
    .sort(
      (a, b) =>
        socialLinksOrder.indexOf(a.name) - socialLinksOrder.indexOf(b.name)
    )
}

const SocialMenu: React.FC<SocialMenuProps> = ({ links, size }) => {
  const fontSize = size !== undefined ? size : 32
  return (
    <ul className={styles.socialMenu} style={{ fontSize: `${fontSize}px` }}>
      {sortSocialLinks(links).map((link) => (
        <SocialLink
          key={`${link.name}-${link.url}`}
          name={link.name}
          link={link.url}
        />
      ))}
    </ul>
  )
}

export default SocialMenu
