import Link from 'next/link'
import { t } from '../utils'
import { songsPath } from '../utils/routeHelpers'
import SocialMenu from './SocialMenu'
import styles from './_footer.module.scss'
import { useChat } from 'react-live-chat-loader'
import { Logo } from './Logo'

export const socialLinks = [
  {
    name: 'twitter',
    url: 'https://twitter.com/worshiponline'
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/WorshipOnline'
  },
  {
    name: 'instagram',
    url: 'https://instagram.com/worshiponline'
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/worshiponline'
  },
  {
    name: 'tiktok',
    url: 'https://www.tiktok.com/@worshiponlineofficial'
  },
  {
    name: 'spotify',
    url: 'https://open.spotify.com/show/7nYOaVMMxCX32LlgB7fyyM?si=98a008d0bf004010'
  },
  {
    name: 'applePodcast',
    url: 'https://podcasts.apple.com/us/podcast/worship-online-podcast/id1330426703'
  }
]

const Footer: React.FC = () => {
  const [, loadChat] = useChat()

  return (
    <div className="section theme-color-wrapper is-hidden-print">
      <footer role="contentifno" className={`${styles.footer} container`}>
        <Link href="/" prefetch={false} className={styles.logo}>
          <Logo width={'100%'} height={'100%'} />
        </Link>

        <div className={styles.footerContainer}>
          <nav className={styles.nav}>
            <ul className={`${styles.navMenu} is-clearfix`}>
              <li>
                <Link href={songsPath()} prefetch={false}>
                  {t('Songs')}
                </Link>
              </li>
              <li>
                <Link
                  href="https://worshiponline.com/features/"
                  prefetch={false}
                >
                  {t('Features')}
                </Link>
              </li>
              <li>
                <Link href="https://worshiponline.com/blog/" prefetch={false}>
                  {t('Blog')}
                </Link>
              </li>
              <li>
                <Link href="/song_requests" prefetch={false}>
                  {t('Song Request')}
                </Link>
              </li>
              <li>
                <Link
                  href="https://worshiponline.com/privacy/"
                  prefetch={false}
                >
                  {t('Privacy Policy')}
                </Link>
              </li>
              <li>
                <Link
                  href="https://worshiponline.com/terms-of-use/"
                  prefetch={false}
                >
                  {t('Terms of Use')}
                </Link>
              </li>
              <li>
                <span
                  className={styles.footerLink}
                  onClick={() => loadChat({ open: true })}
                >
                  {t('Contact')}
                </span>
              </li>
              <li>
                <Link
                  href="https://help.worshiponline.com/en/"
                  prefetch={false}
                >
                  {t('Help Center')}
                </Link>
              </li>
            </ul>
          </nav>

          <SocialMenu links={socialLinks} />

          <div className={styles.copyright}>
            © {new Date().getFullYear()} {t('Worship Online')}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
